module.exports = {
  title: 'Khaled Garbaya · Doing Javascript and stuff.',
  description: '',
  url: 'https://khaledgarbaya.net',
  image: 'https:khaledgarbaya.net/logo.png',
  logo: 'https://khaledgarbaya.net/favicons/android-chrome-512x512.png',
  twitter: '@khaled_garbaya',
  fbAppID: '',
  author: {
    minibio: `
Khaled Garbaya is a software developer and active opensourcerer at Contentful. He speaks multiple languages and is often overheard saying "Bonjour" in HTML
    `
  }
}
